<template>
  <v-app>
    <v-app-bar app class="mx-auto">
      <router-link to="/home" style="text-decoration:none">
        <v-toolbar-title @click="resetPage" class="ml-5 text-white font-weight-bold"> AnimeGFans</v-toolbar-title>
        <!-- <v-btn text rounded @click="resetPage">首頁</v-btn> -->
      </router-link>
      <v-spacer></v-spacer>
      <v-text-field v-if="routerHome()" hide-details solo-inverted rounded append-inner-icon="mdi-magnify"
        style="margin-right: 10px; max-width: 300px; margin-left: 10px" v-model="keywords" @input="resetPage" variant="solo-filled" />
      <v-btn v-if="myStore.getUser()"  @click="logout()">登出</v-btn>
    </v-app-bar>
    <v-main>
      <router-view :keywords="keywords" :page="page" @update-page="updatePage" />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
//
import { ref } from 'vue';
import { useAppStore } from '@/store/app';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

const keywords = ref('');
const page = ref(1);
const router = useRouter()
const myStore = useAppStore()

const updatePage = (newPage: number) => {
  page.value = newPage;
};

const logout = () => {
  router.push({ name: 'index' })
  myStore.logout()
}

const routerHome = () => {
  return router.currentRoute.value.name === "home";
}

const resetPage = () => {
  page.value = 1;
}

</script>
