// Utilities
import { defineStore } from "pinia";
import axios from "axios";

export const useAppStore = defineStore("app", {
  state: () => ({
    // get token from cookie
    user: getCookie("token")
  }),
  actions: {
    // login
    async login(payload: { username: string; password: string }) {
      return await axios
        .post("https://animeapi.aylu.tw/login", payload)
        .then(({ data }) => {
          var date = new Date();
          date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 100000)); // 計算未來時間
          var expires = "expires=" + date.toUTCString(); // 設置為 UTC 字串格式
          document.cookie = `token=${data.token};${expires};path=/`;
          this.user = data.token;
        });
    },
    // logout
    async logout() {
      document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
      this.user = "";
    },
    getUser() {
      return this.user;
    },
    setUser(token: string) {
      this.user = token;
    }
  },
});


const getCookie = (name: string): string => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
}
