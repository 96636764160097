/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import axios from "axios";
import { log } from "console";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import("@/pages/index.vue"),
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/pages/home.vue"),
      meta: {
        title: '首頁',
        requiresAuth: true
      }
    },
    {
      path: '/video/:id',
      name: 'video',
      component: () => import('@/pages/video.vue'),
      props: true,
      meta: {
        title: '影片播放',
        requiresAuth: true
      }
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("@/pages/not-found.vue"),
    },

  ],
});

router.beforeEach(async (to, from, next) => {
  console.log(to)
  const token = getCookie('token');

  try {
    // if (token) {
    //   // check to refresh token
    //   axios.defaults.headers.common['Authorization'] = token
    //   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    //   // check time if is need to refresh token, if expire in 10 minutes, refresh token
    //   if (new Date().getTime() > new Date(atob(token.split(".")[1])).getTime() - 10 * 60 * 1000) {
    //     const response = await axios.post('https://animeapi.aylu.tw/refresh', { token: token })
    //     if (response.data.token) {
    //       var date = new Date();
    //       date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000)); // 計算未來時間
    //       var expires = "expires=" + date.toUTCString(); // 設置為 UTC 字串格式
    //       document.cookie = `token=${response.data.token};${expires};path=/`;
    //     }
    //   }
    // }
  } catch (error) {
  }

  var nextTemp = null
  if (to.meta.title) {
    document.title = to.meta.title.toString()
  }
  if (to.matched.some((record) => record.meta.requiresAuth) && token == "") {
    nextTemp = to
    document.title = '登入'
    next('/')
    return
  }

  if (token && nextTemp != null) {
    next(nextTemp)
    return
  }

  if (token && (to.path == '/' || to.path == '/login')) {
    next('/home')
    return
  }

  next()
})

const getCookie = (name: string): string => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
}

export default router;
